import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { nanoid } from "nanoid";
import debounce from "lodash.debounce";
import { useUpdateEditor } from "../editorPage/atomHelpers/editorUpdate";
import { useNotifySidebarUpdate } from "../sidebar/atoms/sidebarUpdate";
import { useSyncNotes } from "../model/sync/useSyncNotes";
import { isPersistenceEnabled, isRunningInIOSWebview } from "../utils/environment";
import { userSettingsAtom, accessTokenAtom, isLoadedAtom, requestAccessTokenUpdateAtom } from "../model/atoms";
import { syncChannel } from "../service-worker/sync-channel";
import { importWorkerURLHack } from "../utils/importWorkerURLHack";
import logger from "../utils/logger";
import { useAutoUploadAudio } from "../editor/features/audioInsert/utils";
import { appLocalStore } from "../model/services";
import { makeUserSettings } from "../model/defaults";
import { useAuth } from "./useAuth";
import { usePromptUserToUpdateApp } from "./usePromptUserToUpdateApp";

export const AccessTokenManager = ({ children }: { children: React.ReactElement }) => {
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth();
  const [accessToken, setAccessToken] = useAtom(accessTokenAtom);
  // This is used to force re-sending the access token when the sw requests it
  // even if the access token has not changed.
  const [sendAuthToWorker, setSendAuthToWorker] = useState(0);
  const requestAccessTokenUpdate = useAtomValue(requestAccessTokenUpdateAtom);

  const refreshToken = useCallback(async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      setAccessToken(accessToken);
    } catch (error) {
      logger.error("Unable to get accessToken", { error });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently]);

  // Set access token on mount and when the refresh token count changes
  useEffect(() => {
    refreshToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestAccessTokenUpdate]);

  // Send access token to SW on change
  useEffect(() => {
    if (isAuthenticated && accessToken && user) {
      appLocalStore.setAuth(accessToken, user);
    }
  }, [isAuthenticated, user, accessToken, setAccessToken, sendAuthToWorker]);

  const sendFreshTokenToSW = useCallback(async () => {
    await refreshToken();
    setSendAuthToWorker((v) => v + 1);
  }, [refreshToken, setSendAuthToWorker]);

  const isSyncWorkerReady = usePersistenceWorker(sendFreshTokenToSW);
  useSyncNotes(isSyncWorkerReady);
  useAutoUploadAudio();

  usePromptUserToUpdateApp(accessToken);

  return children;
};

// This hook registers the Service Worker than can be used to sync the user data to the browser cache and eventually to the DB
// It returns a boolean indicating whether the service worker is ready to receive messages
function usePersistenceWorker(sendFreshTokenToSW: () => Promise<void>) {
  const notifyEditorUpdate = useUpdateEditor();
  const notifySidebarUpdate = useNotifySidebarUpdate();
  const [isLoaded, setIsLoaded] = useAtom(isLoadedAtom);
  const [isSWReady, setIsSWReady] = useState(false);
  const setUserSettings = useSetAtom(userSettingsAtom);

  const update = useCallback(() => {
    notifyEditorUpdate();
    notifySidebarUpdate();
  }, [notifyEditorUpdate, notifySidebarUpdate]);
  const debouncedUpdate = useMemo(() => debounce(update, 500, { trailing: true }), [update]);
  // The id of the currently active and controlling service worker
  const [, setSWId] = useState(nanoid());

  // Set up service worker
  useEffect(() => {
    if (!navigator.serviceWorker && isPersistenceEnabled && !isRunningInIOSWebview)
      throw new Error("We only support browsers with service workers enabled");
    if (!navigator.serviceWorker && !isPersistenceEnabled) return;

    appLocalStore.refreshEditor = debouncedUpdate;
    appLocalStore.markDataAsLoaded = () => {
      if (!isLoaded) {
        setIsLoaded(true);
        update();
      }
    };
    appLocalStore.sendFreshTokenToSW = sendFreshTokenToSW;
    appLocalStore.userSettingsHandler = (settings) => setUserSettings(makeUserSettings(settings));
    appLocalStore.isLoaded = isLoaded;

    const handler = appLocalStore.handleSyncData.bind(appLocalStore);

    if (isRunningInIOSWebview) {
      import("../service-worker/sync-worker").then(({ initializeSyncWorkerOnMainThread }) => {
        initializeSyncWorkerOnMainThread();
        setIsSWReady(true);
      });
      syncChannel.port2.addEventListener("message", handler);
      return () => {
        syncChannel.port2.removeEventListener("message", handler);
      };
    }

    registerSW(() => setIsSWReady(true), setSWId);
    navigator.serviceWorker.addEventListener("message", handler);
    return () => {
      navigator.serviceWorker.removeEventListener("message", handler);
    };
  }, [setSWId, update, debouncedUpdate, sendFreshTokenToSW, setIsLoaded, isLoaded, setUserSettings]);

  return isSWReady;
}

function registerSW(readyCallback: () => void, setSWId: (id: string) => void) {
  const url = importWorkerURLHack(() => new Worker(new URL("./../service-worker/index.ts", import.meta.url)));
  // We need to change the scope to / since the script compiled by nextjs will be placed in the _next/static/chunks directory
  // By default the service worker takes the scope of its source files and only handles requests from pages under that scope
  // To change the scope we must allow it by attaching the Service-Worker-Allowed header (see next.config.js)
  navigator.serviceWorker.register(url, { scope: "/" }).then(
    async (_registration) => {
      await navigator.serviceWorker.ready;
      logger.info("service worker is ready", { namespace: "sync-main" });
      readyCallback();
    },
    (err) => logger.error(err),
  );

  // The ServiceWorker that was just registered still needs to "claim" this page
  // and the `navigator.serviceWorker.controller` will not be available until this happes
  // Waiting on the navigator.serviceWorker.register(...) promise or navigator.serviceWorker.ready
  // is not enough, since a service worker may be activated without claiming pages at all
  navigator.serviceWorker.addEventListener("controllerchange", (e) => {
    logger.info("change of controller", { namespace: "sync-main" });
    setSWId(nanoid());
  });
}
