import { useEffect, useMemo, useState } from "react";
import posthog from "posthog-js";
import { useHotkeys } from "../shortcuts/useHotkeys";
import { devOnlyLogoutShortcut } from "../shortcuts/rawShortcuts";
import { generateId } from "../model/generateId";
import { redirectUri, isRunningInIOSWebview } from "../utils/environment";
import logger from "../utils/logger";
import { appLocalStore } from "../model/services";
import { ModalEnum, openModal } from "../model/modals";
import { promiseWithTimeout } from "../utils/misc";
import { getSyncStats } from "../model/sync/SyncStats";
import { createSyncStatsWithDatetimes } from "../model/sync/createSyncStatsWithDatetimes";
import { computeSyncStatus } from "../model/sync/SyncStatus";
import { useAuth } from "./useAuth";

const SW_ACK_MAX_WAIT = 2000;

function createLogoutChannel() {
  return typeof BroadcastChannel === "undefined" ? null : new BroadcastChannel("logout");
}

const clientId = generateId();

export function useLogoutListener() {
  const { logout } = useAuth();
  const logoutChannel = useMemo(createLogoutChannel, []);
  useEffect(() => {
    if (!logoutChannel) return;
    const handler = (e: MessageEvent<string>) => {
      if (e.data === clientId) return;
      logout({ returnTo: redirectUri });
    };
    logoutChannel.addEventListener("message", handler);
    return () => logoutChannel.removeEventListener("message", handler);
  }, [logout, logoutChannel]);
}

/**
 * Returns a function that logs out all tabs.
 * For the other tabs to handle this logout request, you need to put {@link useLogoutListener}
 * at the root of the app (e.g. in MainLayout)
 */
export function useLogoutAllTabs() {
  const { logout } = useAuth();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const logoutChannel = useMemo(createLogoutChannel, []);
  posthog.reset();
  async function logoutAllTabs(unsyncedLogoutConfirmed?: boolean) {
    const authLogger = logger.with({ namespace: "auth" });

    if (appLocalStore.hasUnsyncedData() && !unsyncedLogoutConfirmed) {
      const syncStats = getSyncStats();
      const syncStatsWithDatetimes = createSyncStatsWithDatetimes(syncStats);
      const status = computeSyncStatus(syncStats);
      const dataDump = {
        ...syncStatsWithDatetimes,
        ...status,
        now: new Date(),
      };
      authLogger.warn("Unsynced during logout", { context: dataDump, report: true });
      authLogger.info("Log out requested, but there is unsynced data. Warning user before proceeding");
      openModal(ModalEnum.UNSYNCED_LOGOUT);
      return;
    }

    if (appLocalStore.hasUnsyncedData() && unsyncedLogoutConfirmed) {
      authLogger.info("Log out requested with unsynced, and user has confirmed warning. Proceeding");
    }

    authLogger.info("Logging out all tabs");
    setIsLoggingOut(true);
    if (!isRunningInIOSWebview) {
      // Logout other tabs. We do this first to avoid possibility of a tab
      // logging the service worker back in by sending an access token.
      logoutChannel?.postMessage(clientId);
    }

    // Ask the service worker to log out before we unregister it
    // Wait for the log out to be acknowledged by the SW before actually proceeding
    try {
      await promiseWithTimeout(SW_ACK_MAX_WAIT, appLocalStore.logout.bind(appLocalStore));
    } catch (e) {
      logger.error(e);
    }

    if (!isRunningInIOSWebview) {
      navigator.serviceWorker
        ?.getRegistrations()
        .then((registrations) => {
          for (const registration of registrations) {
            registration.unregister();
          }
        })
        .catch((e) => {
          authLogger.info("Service Worker registration failed: ", { error: e });
        });
    }
    // Logout this tab
    // In the native app, the web view origin is "thoughtstream-app://". But
    // auth0 doesn't accept that as a valid url without a trailing slash. So we
    // need to add a slash here to make it "thoughtstream-app:///".
    logout({ returnTo: redirectUri });
  }
  return { logoutAllTabs, isLoggingOut };
}

export function LogoutDevShortcut() {
  const { logoutAllTabs } = useLogoutAllTabs();
  useHotkeys(
    {
      id: "dev-only-logout",
      keys: devOnlyLogoutShortcut,
      label: "Dev only logout",
      isCustomizable: false,
    },
    () => logoutAllTabs(),
  );

  return null;
}
